const utils = {
  mapProductParams(product) {
    return {
      professional: 'nessus',
      expert: 'expert',
    }[product];
  },

  getProductName(id) {
    return {
      nessus: 'Nessus Professional',
      professional: 'Nessus Professional',
      essentials: 'Nessus Essentials',
      expert: 'Nessus Expert',
      manager: 'Nessus Manager',
    }[id];
  },

  getEnvVars(env = 'production') {
    let baseUrl = 'https://www.tenable.com';
    if (env === 'local') baseUrl = 'http://localhost:3000';
    if (env === 'dev') baseUrl = 'https://dev.tenable.com';
    if (env === 'staging') baseUrl = 'https://staging.tenable.com';

    return { baseUrl, environment: env };
  },

  createAppsArr(product) {
    if (product === 'nessus') {
      return ['nessus'];
    }

    if (product === 'expert') {
      return ['expert'];
    }

    if (product === 'essentials') {
      return ['essentials'];
    }

    return [];
  },

  getLocaleStrings(strings = {}, locale = 'en') {
    return strings[locale];
  },
};

export default utils;
