import utils from './utils';
import strings from '../i18n/utils/forms';

const forms = {
  escapeHTML(html) {
    const escape = document.createElement('textarea');
    escape.textContent = html;
    return escape.innerHTML;
  },

  checkEmailDomain(value, fieldVar, config) {
    const defaultStrings = utils.getLocaleStrings(strings, config?.language ?? 'en');
    const domainEntered = value.split('@')[1];
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    if (!fieldVar) return true;

    if (value.length === 0) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, config?.strings?.errors?.emailBlank ?? defaultStrings.errors.emailBlank);
      } else {
        this.showErr(config.errMsg, config?.strings?.errors?.emailBlank ?? defaultStrings.errors.emailBlank);
      }
      return false;
    }

    if (!(emailRegExp.test(value))) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, config?.strings?.errors?.emailInvalid ?? defaultStrings.errors.emailInvalid);
      } else {
        this.showErr(config.errMsg, config?.strings?.errors?.emailInvalid ?? defaultStrings.errors.emailInvalid);
      }
      return false;
    }

    /// Don't check banned domains for Essentials
    if (config.product === 'essentials') return true;

    const bannedDomains = this?.bannedDomains ?? ['gmail.com'];
    if (bannedDomains.indexOf(domainEntered.toLowerCase()) > -1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(
          fieldVar, config?.strings?.errors?.emailWorkDomain ?? defaultStrings.errors.emailWorkDomain,
        );
      } else {
        this.showErr(config.errMsg, config?.strings?.errors?.emailWorkDomain ?? defaultStrings.errors.emailWorkDomain);
      }
      return false;
    }

    return true;
  },

  checkFieldValue(value, fieldVar, fieldName, config) {
    const defaultStrings = utils.getLocaleStrings(strings, config?.language ?? 'en');

    const badChars = /[|%"<>@\\]/g;
    const badJS = /javascript:/i;

    if (!fieldVar) return true;

    if (value.length < 1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      }
      return false;
    }

    if (badChars.test(value) || badJS.test(value)) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config?.strings?.errors?.invalidCharacters ?? defaultStrings.errors.invalidCharacters}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config?.strings?.errors?.invalidCharacters ?? defaultStrings.errors.invalidCharacters}`);
      }
      return false;
    }

    return true;
  },

  checkPasswordValue(value, fieldVar, fieldName, config) {
    const defaultStrings = utils.getLocaleStrings(strings, config?.language ?? 'en');
    const badJS = /javascript:/i;

    if (!fieldVar) return true;

    if (value.length < 1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      }
      return false;
    }

    if (badJS.test(value)) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config?.strings?.errors?.invalidCharacters ?? defaultStrings.errors.invalidCharacters}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config?.strings?.errors?.invalidCharacters ?? defaultStrings.errors.invalidCharacters}`);
      }
      return false;
    }

    return true;
  },

  checkSelectValue(value, fieldVar, fieldName, config) {
    const defaultStrings = utils.getLocaleStrings(strings, config?.language ?? 'en');

    if (!fieldVar) return true;
    if (value.length < 1) {
      fieldVar.classList.add('invalid');
      if (config.fieldError) {
        this.showErrBelowField(fieldVar, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      } else {
        this.showErr(config.errMsg, `${fieldName} ${config?.strings?.errors?.blank ?? defaultStrings.errors.blank}`);
      }
      return false;
    }

    return true;
  },

  showErrBelowField(fieldVar, msg) {
    fieldVar.parentElement.classList.add('invalid');
    fieldVar.parentElement.querySelector('[data-name="form-field-error"]').textContent = msg;
  },

  removeFieldError(fieldVar) {
    fieldVar.classList.remove('invalid');
    fieldVar.parentElement.classList.remove('invalid');
    fieldVar.parentElement.querySelector('[data-name="form-field-error"]').textContent = '';
  },

  fieldFocus(fieldVar) {
    fieldVar.parentElement.classList.add('focused');
    fieldVar.parentElement.classList.add('typing');
  },

  fieldBlur(fieldVar) {
    if (fieldVar.tagName === 'SELECT') {
      if (fieldVar.options[fieldVar.selectedIndex].text.trim() === '') {
        fieldVar.parentElement.classList.remove('focused');
        fieldVar.parentElement.classList.remove('typing');
      } else {
        fieldVar.parentElement.classList.add('focused');
        fieldVar.parentElement.classList.remove('typing');
      }
    } else if (fieldVar.value < 1) {
      fieldVar.parentElement.classList.remove('focused');
      fieldVar.parentElement.classList.remove('typing');
    } else {
      fieldVar.parentElement.classList.add('focused');
      fieldVar.parentElement.classList.remove('typing');
    }
  },

  validateField(event, fieldVar, fieldString, config, fieldName) {
    if (event.type === 'keyup' && event.keyCode === 9) {
      return;
    }

    const fieldVal = this.escapeHTML(fieldVar.value);

    if (fieldName === 'email') {
      const isValid = this.checkEmailDomain(fieldVal, fieldVar, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    } else if (fieldName === 'password') {
      const isValid = this.checkPasswordValue(fieldVal, fieldVar, fieldString, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    } else if (fieldName === 'companySize') {
      const isValid = this.checkSelectValue(fieldVal, fieldVar, fieldString, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    } else {
      const isValid = this.checkFieldValue(fieldVal, fieldVar, fieldString, config);
      if (isValid) {
        this.removeFieldError(fieldVar);
      }
    }
  },
};

export default forms;
