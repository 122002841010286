import contactLookup from './contactLookup';
import newContact from './newContact';
import existingContact from './existingContact';
import existingTrial from './existingTrial';
import registerCode from '../common/registerCode';

const activation = {
  targetDivSelector: 't-nessus-trial-activation-container',

  generateHTML({ options }) {
    return `
      <div id="${contactLookup.targetDivSelector}" ${this.env ? `data-env="${this.env}"` : ''} ${options?.showRegisterCode === true ? 'data-show-register-code="true"' : ''}></div>
      <div id="${newContact.targetDivSelector}" ${this.env ? `data-env="${this.env}"` : ''}></div>
      <div id="${existingContact.targetDivSelector}" ${this.env ? `data-env="${this.env}"` : ''}></div>
      <div id="${existingTrial.targetDivSelector}" ${this.env ? `data-env="${this.env}"` : ''}></div>
      <div id="${registerCode.targetDivSelector}" ${this.env ? `data-env="${this.env}"` : ''}></div>
    `;
  },

  render({ parentDiv, targetDiv, env, options }) {
    targetDiv.innerHTML = this.generateHTML({ options });

    this.config = {
      env,
      options,
      targetDiv,
      parentDiv,
    };

    contactLookup.show({
      parentDiv: targetDiv,
      back: () => this.back(),
    });
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {}, options = {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;

    this.env = env;
    this.back = back;
    this.render({ parentDiv, targetDiv, env, options });
  },
};

export default activation;
