const strings = {
  en: {
    heading: 'Activate Trial',
    subHeading: 'Please select an available trial.',
    backBtn: 'Back',
    submitBtn: 'Start Trial',
    submitting: 'Sending...',
    defaultError: 'Activate trial error',
    consentDisclosure: 'By registering for this trial license, Tenable may send you email communications regarding its products and services. You may opt out of receiving these communications at any time by using the unsubscribe link located in the footer of the emails delivered to you. You can also manage your Tenable email preferences by visiting the <a target="_blank" href="https://info.tenable.com/SubscriptionManagement.html">Subscription Management Page</a>.',
    consentFinePrint: 'Tenable will only process your personal data in accordance with its <a target="_blank" href="https://www.tenable.com/privacy-policy">Privacy Policy</a>.',
    codePlaceholder: 'Please select a trial...',
    code: 'Trial',
  },
};

export default strings;
