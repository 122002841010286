/* eslint-disable no-underscore-dangle */

class Nessus {
  constructor(config) {
    this.config = config;
  }

  showLoader() {
    if (this?.config?.env?.environment !== 'production') { console.log('Calling _Loading.show()...'); }

    return window?._Loading?.show();
  }

  hideLoader() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Loading.hide()...');

    return window?._Loading?.hide();
  }

  centerView() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _View.center()...');

    return window?._View?.center();
  }

  async setActivationCode(data = {}) {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.setActivationCode()...', JSON.stringify(data, null, 2));

    try {
      window?._Iron?.core?.status?.setActivationCode(data.code, data?.expirationDate);
      await this.#activationCodeCallback(data);
    } catch (error) {
      console.error('Set Activation Code', error);
      await this.#activationCodeCallback({ ...data, error: error?.message });
    }
  }

  async #activationCodeCallback(data = {}) {
    try {
      await fetch(`${this?.config?.env?.baseUrl}/evaluations/api/v1/nessus/activations/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error('Activation Code Callback', error);
    }
  }

  showManagedScanner() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.renderManagedScannerConfiguration()...');

    return window?._Iron?.core?.status?.renderManagedScannerConfiguration();
  }

  fallbackActivation() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.fallbackActivation()...');

    return window?._Iron?.core?.status?.fallbackActivation();
  }

  backToActivationMethods() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.backToActivationMethods()...');

    return window?._Iron?.core?.status?.backToActivationMethods();
  }

  backToSettings() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.backToSettings()...');

    return window?._Iron?.core?.status?.backToSettings();
  }

  showWelcomeView() {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Iron.core.status.renderSettings()...');

    return window?._Iron?.core?.status?.renderSettings();
  }

  initSelectInputs() {
    if (this?.config?.env?.environment !== 'production') console.log("Calling jQuery('select').select2()...");

    return window.jQuery && window.jQuery('select').select2();
  }

  showNotification({ message, type = 'error' }) {
    if (this?.config?.env?.environment !== 'production') console.log('Calling _Notifications.show()...', JSON.stringify({ message, type }, null, 2));

    return window?._Notifications?.show({ message, type });
  }

  getVersion() {
    if (this?.config?.env?.environment !== 'production') console.log('_Iron.core.mismatch.frontend...');

    return window?._Iron?.core?.mismatch?.frontend;
  }
}

export default Nessus;
