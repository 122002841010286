import { utils, forms, Nessus } from '../../utils';
import newContact from './newContact';
import existingTrial from './existingTrial';
import existingContact from './existingContact';
import registerCode from '../common/registerCode';
import strings from '../../i18n/components/trials/contactLookup';

const contactLookup = {
  targetDivSelector: 't-nessus-activation-contact-lookup-container',
  ...forms,
  utils,

  generateHTML({ defaultStrings, product, options }) {
    const productName = this.utils.getProductName(product);

    return `
      <form data-name="activation-contact-lookup-form" class="t-activation-form">
        <div class="register-content">
          <h2>${defaultStrings.heading}</h2>
          <div class="desc mb10">${defaultStrings.subHeading(productName)}</div>
          <div class="t-activation-form-error hidden" data-name="form-error"></div>

          <div class="form-group">
            <label class="t-activation-label" for="email">${defaultStrings.email}</label>
            <input type="email" name="email" required class="t-activation-input required" placeholder="${defaultStrings.email}" aria-label="${defaultStrings.email}" >
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>
        </div>

        <div class="pb20">
          ${options?.showRegisterCode === true ? `<small>${defaultStrings.skipStepDesc}</small>` : ''}
        </div>

        <div class="register-buttons">
          <div class="left-side">
            <button data-name="btn-back" data-testid="btn-back-contact-lookup" tabindex="1" type="button" class="button link">${defaultStrings.backBtn}</button>
          </div>
          <div class="right-side">
            <button data-name="btn-skip" tabindex="1" type="button" class="button link ${options?.showRegisterCode === true ? '' : 'hidden'}">${defaultStrings.skipBtn}</button>
            <button data-name="btn-continue" data-testid="btn-continue-contact-lookup" tabindex="0" type="submit" class="button secondary">${defaultStrings.submitBtn}</button>
          </div>
        </div>
        <div class="clear"></div>
      </form>
    `;
  },

  showSendingState() {
    const { submitBtn, backBtn, skipBtn, formErrorDiv, email, defaultStrings } = this.config;

    this.nessus.showLoader();

    submitBtn.innerHTML = defaultStrings.submitting;
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;

    backBtn.classList.add('disabled');
    backBtn.disabled = true;

    skipBtn.classList.add('disabled');
    skipBtn.disabled = true;

    formErrorDiv.classList.add('hidden');
    formErrorDiv.innerHTML = '';

    email.classList.remove('invalid');
  },

  hideSendingState() {
    const { submitBtn, backBtn, skipBtn, defaultStrings } = this.config;
    this.nessus.hideLoader();

    submitBtn.innerHTML = defaultStrings.submitBtn;
    submitBtn.classList.remove('disabled');
    submitBtn.removeAttribute('disabled');

    backBtn.classList.remove('disabled');
    backBtn.removeAttribute('disabled');

    skipBtn.classList.remove('disabled');
    skipBtn.removeAttribute('disabled');
  },

  showFormError(error = '') {
    const { formErrorDiv } = this.config;

    formErrorDiv.classList.remove('hidden');
    formErrorDiv.innerHTML = `<strong>${error}</strong>`;
    this.nessus.showNotification({ message: error });
  },

  validateForm(validations) {
    return validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );
  },

  showExistingTrial() {
    this.hide();
    existingTrial.show({
      parentDiv: this.config.parentDiv,
      back: () => this.show({ parentDiv: this.config.parentDiv, back: this.back }),
    });
  },

  showExistingContact() {
    this.hide();
    existingContact.show({
      parentDiv: this.config.parentDiv,
      back: () => this.show({ parentDiv: this.config.parentDiv, back: this.back }),
    });
  },

  showNewContact() {
    this.hide();
    newContact.show({
      parentDiv: this.config.parentDiv,
      back: () => this.show({ parentDiv: this.config.parentDiv, back: this.back }),
    });
  },

  async lookupContactEmail(email) {
    const { defaultStrings } = this.config;

    let resp = { exists: false };
    try {
      const response = await fetch(`${this.config.env.baseUrl}/evaluations/api/v1/contacts/${email}?product=${this.config.product}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const json = await response.json();
      if (!response.ok) throw new Error(json?.message ?? response.statusText);

      resp = json;
    } catch (err) {
      console.error('Account Lookup Error: ', err);
      this.showFormError(err?.message ?? defaultStrings.defaultError);
      resp.error = true;
    }

    return resp;
  },

  async handleSubmit(e) {
    e.preventDefault();
    this.showSendingState();

    const data = {
      email: this.escapeHTML(this.config.email.value),
    };

    const isValid = this.validateForm(
      [
        this.checkEmailDomain(data.email, this.config.email, this.config),
      ],
    );

    if (isValid) {
      const { error = false, exists = false, data: contact } = await this.lookupContactEmail(data.email);

      const evals = contact?.evals?.[this.config.product] ?? [];
      window.activation.state = { ...window.activation.state, ...{ contact } };

      if (!error && exists && evals.length) this.showExistingTrial();
      if (!error && exists && !evals.length) this.showExistingContact();
      if (!error && !exists) this.showNewContact();
    }

    this.hideSendingState();
  },

  handleBackClick() {
    this.hide();
    this.back();
  },

  handleSkipClick() {
    this.hide();
    registerCode.show({
      parentDiv: this.config.parentDiv,
      back: () => this.show({ parentDiv: this.config.parentDiv, back: this.back }),
    });
  },

  addEventListeners() {
    this.config.submitBtn.addEventListener('click', (e) => this.handleSubmit(e));
    this.config.backBtn.addEventListener('click', (e) => this.handleBackClick(e));
    this.config.skipBtn.addEventListener('click', (e) => this.handleSkipClick(e));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.email.addEventListener(e, (event) => this.validateField(event, this.config.email, this.config.defaultStrings.email, this.config, 'email')));
  },

  render({ targetDiv, parentDiv, env, options }) {
    const product = window?.activation?.trial?.state?.product ?? 'nessus';
    const defaultStrings = this.utils.getLocaleStrings(strings);

    targetDiv.classList.remove('hidden');
    targetDiv.innerHTML = this.generateHTML({ defaultStrings, product, options });

    const formDiv = targetDiv.querySelector('[data-name="activation-contact-lookup-form"]');
    this.config = {
      env: this.utils.getEnvVars(env),
      options,
      defaultStrings,
      targetDiv,
      parentDiv,
      product,
      fieldError: true,
      formDiv,
      formErrorDiv: formDiv.querySelector('[data-name="form-error"]'),
      submitBtn: formDiv.querySelector('[data-name="btn-continue"]'),
      backBtn: formDiv.querySelector('[data-name="btn-back"]'),
      skipBtn: formDiv.querySelector('[data-name="btn-skip"]'),
      email: formDiv.querySelector('[name=email]'),
    };

    this.nessus = new Nessus(this.config);
    this.nessus.centerView();

    this.addEventListeners();
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;
    const { showRegisterCode } = targetDiv.dataset;
    const options = { showRegisterCode: showRegisterCode === 'true' };

    this.back = () => {
      targetDiv.innerHTML = '';
      back();
    };
    this.render({ targetDiv, parentDiv, env, options });
  },
};

export default contactLookup;
