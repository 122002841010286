const strings = {
  en: {
    errors: {
      emailBlank: 'Email address cannot be blank',
      emailInvalid: 'Email address is not valid',
      emailWorkDomain: 'Please use a work email address',
      blank: 'cannot be blank',
      invalidCharacters: 'contains invalid characters',
      serverFail: 'Server Error. Please email ',
    },
  },
};

export default strings;
