import { utils, forms, Nessus } from '../../utils';
import strings from '../../i18n/components/trials/existingTrial';

const existingTrial = {
  targetDivSelector: 't-nessus-activation-existing-trial-container',
  ...forms,
  utils,

  generateHTML({ defaultStrings, activeEvals = [] }) {
    const availableTrialOptions = () => `
      ${activeEvals.reduce((previousValue, currentValue) => `
        ${previousValue}
        <option value="${currentValue.code}">${this.utils.getProductName(currentValue.product)} - ${currentValue.code}</option>
      `, '')}
    `;

    return `
      <form data-name="activation-new-eval-form" class="t-activation-form">
        <div class="register-content">
          <h2>${defaultStrings.heading}</h2>
          <div class="desc mb10">${defaultStrings.subHeading}</div>
          <div class="t-activation-form-error hidden" data-name="form-error"></div>

          <div class="form-group">
            <select class="t-activation-select" name="code" required>
              ${activeEvals.length > 1 && `<option value="">${defaultStrings.codePlaceholder}</option>`}
              ${availableTrialOptions()}
            </select>
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>
        </div>

        <p class="pb10"><small>${defaultStrings.consentDisclosure}</small></p>
        <p class="pb20"><small>${defaultStrings.consentFinePrint}</small></p>

        <div class="register-buttons">
          <div class="left-side">
            <button data-name="btn-back" tabindex="1" type="button" class="button link">${defaultStrings.backBtn}</button>
          </div>
          <div class="right-side">
            <button data-name="btn-continue" tabindex="0" type="submit" class="button secondary">${defaultStrings.submitBtn}</button>
          </div>
        </div>
        <div class="clear"></div>
      </form>
    `;
  },

  showSendingState() {
    const { submitBtn, backBtn, formErrorDiv, code, defaultStrings } = this.config;

    this.nessus.showLoader();

    submitBtn.innerHTML = defaultStrings.submitting;
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;

    backBtn.classList.add('disabled');
    backBtn.disabled = true;

    formErrorDiv.classList.add('hidden');
    formErrorDiv.innerHTML = '';

    code.classList.remove('invalid');
  },

  hideSendingState() {
    const { submitBtn, backBtn, defaultStrings } = this.config;
    this.nessus.hideLoader();

    submitBtn.innerHTML = defaultStrings.submitBtn;
    submitBtn.classList.remove('disabled');
    submitBtn.removeAttribute('disabled');

    backBtn.classList.remove('disabled');
    backBtn.removeAttribute('disabled');
  },

  showFormError(error = '') {
    const { formErrorDiv } = this.config;

    formErrorDiv.classList.remove('hidden');
    formErrorDiv.innerHTML = `<strong>${error}</strong>`;
    this.nessus.showNotification({ message: error });
  },

  validateForm(validations) {
    return validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );
  },

  async setActivationCode(data = {}) {
    await this.nessus.setActivationCode(data);
    this.hide();
  },

  async activateTrial(data) {
    const { defaultStrings } = this.config;

    let resp = { };
    const payload = { ...data, product: this.config.product };

    try {
      const response = await fetch(`${this.config.env.baseUrl}/evaluations/api/v1/nessus/activations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const json = await response.json();
      if (!response.ok) throw new Error(json?.message ?? response.statusText);

      resp = json;
    } catch (err) {
      console.error('Activate Trial', err);
      this.showFormError(err?.message ?? defaultStrings.defaultError);
    }

    return resp;
  },

  async handleSubmit(e) {
    e.preventDefault();
    this.showSendingState();

    const data = {
      code: this.escapeHTML(this.config.code.value),
      metaData: {
        requestType: 'Existing Trial',
        email: window?.activation?.state?.contact?.email,
        product: this.config.product,
      },
    };

    const isValid = this.validateForm([
      this.checkSelectValue(data.code, this.config.code, this.config.defaultStrings.code, this.config),
    ]);

    if (isValid) {
      const resp = await this.activateTrial(data);
      if (resp.code) await this.setActivationCode(resp);
    }

    this.hideSendingState();
  },

  handleBackClick() {
    this.hide();
    this.back();
  },

  addEventListeners() {
    this.config.submitBtn.addEventListener('click', (e) => this.handleSubmit(e));
    this.config.backBtn.addEventListener('click', (e) => this.handleBackClick(e));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.code.addEventListener(e, (event) => this.validateField(event, this.config.code, 'Trial', this.config, 'code')));
  },

  render({ targetDiv, parentDiv, env }) {
    const product = window?.activation?.trial?.state?.product ?? 'nessus';
    const evals = window?.activation?.state?.contact?.evals ?? {};
    const activeEvals = evals[product] || [];
    const defaultStrings = this.utils.getLocaleStrings(strings);

    targetDiv.classList.remove('hidden');
    targetDiv.innerHTML = this.generateHTML({ defaultStrings, activeEvals });

    const formDiv = targetDiv.querySelector('[data-name="activation-new-eval-form"]');
    this.config = {
      env: this.utils.getEnvVars(env),
      defaultStrings,
      targetDiv,
      parentDiv,
      product,
      fieldError: true,
      formDiv,
      formErrorDiv: formDiv.querySelector('[data-name="form-error"]'),
      submitBtn: formDiv.querySelector('[data-name="btn-continue"]'),
      backBtn: formDiv.querySelector('[data-name="btn-back"]'),
      code: formDiv.querySelector('[name=code]'),
    };

    this.nessus = new Nessus(this.config);
    this.nessus.initSelectInputs();
    this.nessus.centerView();

    this.addEventListeners();
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;

    this.back = () => {
      targetDiv.innerHTML = '';
      back();
    };
    this.render({ targetDiv, parentDiv, env });
  },
};

export default existingTrial;
