import utils from '../../utils/utils';
import Nessus from '../../utils/nessus';
import forms from '../../utils/forms';
import strings from '../../i18n/components/common/registerCode';

const registerCode = {
  targetDivSelector: 't-nessus-activation-register-code-container',
  ...forms,
  utils,

  generateHTML({ defaultStrings, options }) {
    return `
      <form data-name="activation-register-code-form" class="t-activation-form">
        <div class="register-content">
          <h2>${defaultStrings.heading}</h2>

          <div class="desc mb5">${options?.isEssentials ? defaultStrings.subHeading : defaultStrings.accountSubHeading}</div>

          <div class="t-activation-form-error hidden" data-name="form-error"></div>

          <div class="form-group">
            <label class="t-activation-label" for="code">${defaultStrings.code}</label>
            <input type="text" name="code" required class="t-activation-input required" placeholder="${defaultStrings.code}" aria-label="${defaultStrings.code}" >
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>
        </div>

        ${options?.isEssentials ? '' : `<div class="pb20"><small>${defaultStrings.supportCase}</small></div>`}

        <div class="register-buttons">
          <div class="left-side">
            <button data-name="btn-back" data-testid="btn-back-register" tabindex="1" type="button" class="button link">${defaultStrings.backBtn}</button>
          </div>
          <div class="right-side">
            <button data-name="btn-continue" data-testid="btn-continue-register" tabindex="0" type="submit" class="button secondary">${defaultStrings.submitBtn}</button>
          </div>
        </div>
        <div class="clear"></div>
      </form>
    `;
  },

  showSendingState() {
    const { submitBtn, backBtn, formErrorDiv, code } = this.config;

    this.nessus.showLoader();

    submitBtn.innerHTML = this.config.defaultStrings.submitting;
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;

    backBtn.classList.add('disabled');
    backBtn.disabled = true;

    formErrorDiv.classList.add('hidden');
    formErrorDiv.innerHTML = '';

    code.classList.remove('invalid');
  },

  hideSendingState() {
    const { submitBtn, backBtn } = this.config;
    this.nessus.hideLoader();

    submitBtn.innerHTML = this.config.defaultStrings.submitBtn;
    submitBtn.classList.remove('disabled');
    submitBtn.removeAttribute('disabled');

    backBtn.classList.remove('disabled');
    backBtn.removeAttribute('disabled');
  },

  showFormError(error = '') {
    const { formErrorDiv } = this.config;

    formErrorDiv.classList.remove('hidden');
    formErrorDiv.innerHTML = `<strong>${error}</strong>`;
    this.nessus.showNotification({ message: error });
  },

  validateForm(validations) {
    return validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );
  },

  async setActivationCode(data = {}) {
    await this.nessus.setActivationCode({ code: data.code });
    this.hide();
  },

  async handleSubmit(e) {
    e.preventDefault();
    this.showSendingState();

    const data = {
      code: this.escapeHTML(this.config.code.value),
    };

    const isValid = this.validateForm(
      [
        this.checkFieldValue(data.code, this.config.code, this.config.defaultStrings.code, this.config),
      ],
    );

    if (isValid) {
      await this.setActivationCode(data);
    }

    this.hideSendingState();
  },

  handleBackClick() {
    this.hide();
    this.back();
  },

  addEventListeners() {
    this.config.submitBtn.addEventListener('click', (e) => this.handleSubmit(e));
    this.config.backBtn.addEventListener('click', (e) => this.handleBackClick(e));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.code.addEventListener(e, (event) => this.validateField(event, this.config.code, this.config.defaultStrings.code, this.config, 'code')));
  },

  render({ targetDiv, parentDiv, env, options }) {
    const defaultStrings = this.utils.getLocaleStrings(strings);

    targetDiv.classList.remove('hidden');
    targetDiv.innerHTML = this.generateHTML({ defaultStrings, options });

    const formDiv = targetDiv.querySelector('[data-name="activation-register-code-form"]');
    this.config = {
      env: this.utils.getEnvVars(env),
      defaultStrings,
      targetDiv,
      parentDiv,
      product: this.product,
      fieldError: true,
      formDiv,
      options,
      formErrorDiv: formDiv.querySelector('[data-name="form-error"]'),
      submitBtn: formDiv.querySelector('[data-name="btn-continue"]'),
      backBtn: formDiv.querySelector('[data-name="btn-back"]'),
      code: formDiv.querySelector('[name=code]'),
    };

    this.nessus = new Nessus(this.config);
    this.nessus.centerView();

    this.addEventListeners();
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {}, options = {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;

    this.back = () => {
      targetDiv.innerHTML = '';
      back();
    };
    this.render({ targetDiv, parentDiv, env, options });
  },
};

export default registerCode;
