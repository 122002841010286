const strings = {
  en: {
    heading: 'Register Nessus',
    subHeading: 'Enter your activation code.',
    accountSubHeading: 'To find your activation code, please sign into your <a href="https://community.tenable.com/login" target="_blank">Tenable Community</a> account or set a password to finish setting up your account.',
    supportCase: 'Having an issue activating a product? Please open a technical support case on the <a href="https://community.tenable.com/s/article/How-to-open-a-Technical-Support-Case-from-Community-Portal" target="_blank">Tenable Community Portal</a>.',
    code: 'Activation Code',
    backBtn: 'Back',
    submitBtn: 'Continue',
    submitting: 'Sending...',
    defaultError: 'Activation code registration error',
  },
};

export default strings;
