const strings = {
  en: {
    heading: 'Get Started',
    subHeading: (product) => `To start your${product ? ` ${product}` : ''} trial, please provide your business email address.`,
    skipStepDesc: 'Already have activation code? Skip this step to enter it manually.',
    email: 'Email',
    backBtn: 'Back',
    skipBtn: 'Skip',
    submitBtn: 'Continue',
    submitting: 'Sending...',
    defaultError: 'Account lookup error',
  },
};

export default strings;
