import { utils, Nessus } from '../js/utils';
import { activation as trialActivation } from '../js/components/trials';

const tenableActivation = {
  targetDivSelector: 't-nessus-activation-container',
  utils,

  generateHTML() {
    return `
      <!-- Trial Form -->
      <div id="t-nessus-trial-activation-container" ${this.env ? `data-env="${this.env}"` : ''}></div>
    `;
  },

  showTrialActivation() {
    window.activation.trial.show({
      parentDiv: this.config.targetDiv,
      back: () => this.hide(),
    });
  },

  render(targetDiv) {
    targetDiv.innerHTML = this.generateHTML();

    this.config = {
      targetDiv,
    };

    this.showTrialActivation();
  },

  hide() {
    this.nessus.backToSettings();
  },

  show({ product = 'professional' }) {
    const targetDiv = document.getElementById(this.targetDivSelector);
    const { env = 'production' } = targetDiv.dataset;

    window.activation.trial.state = {
      ...window.activation.trial.state,
      ...{
        product: this.utils.mapProductParams(product),
        env,
      },
    };

    this.env = env;
    this.nessus = new Nessus({ env: this.utils.getEnvVars(env) });
    this.render(targetDiv);
  },

  init() {
    window.activation = tenableActivation;
    window.activation.trial = trialActivation;
  },
};

tenableActivation.init();

export default tenableActivation;
