import { utils, forms, Nessus } from '../../utils';
import strings from '../../i18n/components/trials/existingContact';

const existingContact = {
  targetDivSelector: 't-nessus-activation-existing-contact-container',
  ...forms,
  utils,

  generateHTML({ defaultStrings }) {
    return `
      <form data-name="activation-new-eval-form" class="t-activation-form">
        <div class="register-content">
          <h2>${defaultStrings.heading}</h2>
          <div class="desc mb10">${defaultStrings.subHeading}</div>
          <div class="t-activation-form-error hidden" data-name="form-error"></div>
        </div>

        <p class="pb10"><small>${defaultStrings.consentDisclosure}</small></p>
        <p class="pb20"><small>${defaultStrings.consentFinePrint}</small></p>

        <div class="register-buttons">
          <div class="left-side">
            <button data-name="btn-back" tabindex="1" type="button" class="button link">${defaultStrings.backBtn}</button>
          </div>
          <div class="right-side">
            <button data-name="btn-continue" tabindex="0" type="submit" class="button secondary">${defaultStrings.submitBtn}</button>
          </div>
        </div>
        <div class="clear"></div>
      </form>
    `;
  },

  showSendingState() {
    const { submitBtn, backBtn, formErrorDiv, defaultStrings } = this.config;

    this.nessus.showLoader();

    submitBtn.innerHTML = defaultStrings.submitting;
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;

    backBtn.classList.add('disabled');
    backBtn.disabled = true;

    formErrorDiv.classList.add('hidden');
    formErrorDiv.innerHTML = '';
  },

  hideSendingState() {
    const { submitBtn, backBtn, defaultStrings } = this.config;
    this.nessus.hideLoader();

    submitBtn.innerHTML = defaultStrings.submitBtn;
    submitBtn.classList.remove('disabled');
    submitBtn.removeAttribute('disabled');

    backBtn.classList.remove('disabled');
    backBtn.removeAttribute('disabled');
  },

  showFormError(error = '') {
    const { formErrorDiv } = this.config;

    formErrorDiv.classList.remove('hidden');
    formErrorDiv.innerHTML = `<strong>${error}</strong>`;
    this.nessus.showNotification({ message: error });
  },

  validateForm(validations) {
    return validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );
  },

  async setActivationCode(data = {}) {
    await this.nessus.setActivationCode(data);
    this.hide();
  },

  async createTrial(data) {
    const { defaultStrings } = this.config;
    let resp = { };

    try {
      const response = await fetch(`${this.config.env.baseUrl}/evaluations/api/v1/nessus/trials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      if (!response.ok) throw new Error(json?.message ?? response.statusText);

      resp = json;
    } catch (err) {
      console.error('Create Trial Error: ', err);
      this.showFormError(err?.message ?? defaultStrings.defaultError);
    }

    return resp;
  },

  async handleSubmit(e) {
    e.preventDefault();
    this.showSendingState();

    const data = {
      product: this.config.product,
      skipContactLookup: false,
      ...window.activation.state.contact,
      metaData: {
        requestType: 'Existing Contact',
        email: window?.activation?.state?.contact?.email,
        product: this.config.product,
      },
    };

    const resp = await this.createTrial(data);
    if (resp.code) await this.setActivationCode(resp);

    this.hideSendingState();
  },

  handleBackClick() {
    this.hide();
    this.back();
  },

  addEventListeners() {
    this.config.submitBtn.addEventListener('click', (e) => this.handleSubmit(e));
    this.config.backBtn.addEventListener('click', (e) => this.handleBackClick(e));
  },

  render({ targetDiv, parentDiv, env }) {
    const product = window?.activation?.trial?.state?.product ?? 'nessus';
    const defaultStrings = this.utils.getLocaleStrings(strings);

    targetDiv.classList.remove('hidden');
    targetDiv.innerHTML = this.generateHTML({ defaultStrings });

    const formDiv = targetDiv.querySelector('[data-name="activation-new-eval-form"]');
    this.config = {
      env: this.utils.getEnvVars(env),
      defaultStrings,
      targetDiv,
      parentDiv,
      product,
      fieldError: true,
      formDiv,
      formErrorDiv: formDiv.querySelector('[data-name="form-error"]'),
      submitBtn: formDiv.querySelector('[data-name="btn-continue"]'),
      backBtn: formDiv.querySelector('[data-name="btn-back"]'),
    };

    this.nessus = new Nessus(this.config);
    this.nessus.centerView();

    this.addEventListeners();
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;

    this.back = () => {
      targetDiv.innerHTML = '';
      back();
    };
    this.render({ targetDiv, parentDiv, env });
  },
};

export default existingContact;
