import { utils, forms, Nessus } from '../../utils';
import strings from '../../i18n/components/trials/newContact';

const newContact = {
  targetDivSelector: 't-nessus-activation-new-contact-container',
  ...forms,
  utils,

  generateHTML({ defaultStrings }) {
    return `
      <form data-name="activation-new-eval-form" class="t-activation-form">
        <div class="register-content">
          <h2>${defaultStrings.heading}</h2>
          <div class="desc mb10">${defaultStrings.subHeading}</div>
          <div class="t-activation-form-error hidden" data-name="form-error"></div>

          <div class="t-activation-form-row">
            <div class="t-activation-form-column">
              <div class="form-group">
                <label class="t-activation-label" for="first_name">${defaultStrings.firstName}</label>
                <input class="t-activation-input" type="text" placeholder="${defaultStrings.firstName}" name="first_name" required>
                <div class="t-activation-form-field-error" data-name="form-field-error"></div>
              </div>
            </div>

            <div class="t-activation-form-column">
              <div class="form-group">
                <label class="t-activation-label" for="last_name">${defaultStrings.lastName}</label>
                <input class="t-activation-input" type="text" placeholder="${defaultStrings.lastName}" name="last_name" required>
                <div class="t-activation-form-field-error" data-name="form-field-error"></div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="t-activation-label" for="email">${defaultStrings.email}</label>
            <input type="email" name="email" required class="t-activation-input required" placeholder="${defaultStrings.email}" aria-label="${defaultStrings.email}" >
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>

          <div class="form-group">
            <label class="t-activation-label" for="Phone">${defaultStrings.phone}</label>
            <input class="t-activation-input" type="text" placeholder="${defaultStrings.phone}" name="phone" required>
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>

          <div class="form-group">
            <label class="t-activation-label" for="job-title">${defaultStrings.title}</label>
            <input class="t-activation-input" type="text" placeholder="${defaultStrings.title}" name="job-title" required>
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>

          <div class="form-group">
            <label class="t-activation-label" for="company-name">${defaultStrings.company}</label>
            <input class="t-activation-input" type="text" placeholder="${defaultStrings.company}" name="company-name" required>
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>

          <div class="form-group">
            <label for="company-size">${defaultStrings.companySize}</label>
            <select class="t-activation-select" id="company-size" name="company-size" required>
              <option value="">${defaultStrings.companySize} </option>
              <option value="1-9">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option1}</option>
              <option value="10-49">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option2}</option>
              <option value="50-99">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option3}</option>
              <option value="100-249">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option4}</option>
              <option value="250-499">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option5}</option>
              <option value="500-999">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option6}</option>
              <option value="1,000-3,499">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option7}</option>
              <option value="3,500-4,999">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option8}</option>
              <option value="5,000-10,000">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option9}</option>
              <option value="10,000+">${defaultStrings.companySize}: ${defaultStrings.companySizeOptions.option10}</option>
            </select>
            <div class="t-activation-form-field-error" data-name="form-field-error"></div>
          </div>
        </div>

        <p class="pb10"><small>${defaultStrings.consentDisclosure}</small></p>
        <p class="pb20"><small>${defaultStrings.consentFinePrint}</small></p>

        <div class="register-buttons">
          <div class="left-side">
            <button data-name="btn-back" tabindex="1" type="button" class="button link">${defaultStrings.backBtn}</button>
          </div>
          <div class="right-side">
            <button data-name="btn-continue" tabindex="0" type="submit" class="button secondary">${defaultStrings.submitBtn}</button>
          </div>
        </div>
        <div class="clear"></div>
      </form>
    `;
  },

  showSendingState() {
    const { submitBtn, backBtn, formErrorDiv, defaultStrings } = this.config;

    this.nessus.showLoader();

    submitBtn.innerHTML = defaultStrings.submitting;
    submitBtn.classList.add('disabled');
    submitBtn.disabled = true;

    backBtn.classList.add('disabled');
    backBtn.disabled = true;

    formErrorDiv.classList.add('hidden');
    formErrorDiv.innerHTML = '';

    this.config.firstName.classList.remove('invalid');
    this.config.lastName.classList.remove('invalid');
    this.config.email.classList.remove('invalid');
    this.config.phone.classList.remove('invalid');
    this.config.company.classList.remove('invalid');
    this.config.title.classList.remove('invalid');
    this.config.companySize.classList.remove('invalid');
  },

  hideSendingState() {
    const { submitBtn, backBtn, defaultStrings } = this.config;
    this.nessus.hideLoader();

    submitBtn.innerHTML = defaultStrings.submitBtn;
    submitBtn.classList.remove('disabled');
    submitBtn.removeAttribute('disabled');

    backBtn.classList.remove('disabled');
    backBtn.removeAttribute('disabled');
  },

  showFormError(error = '') {
    const { formErrorDiv } = this.config;

    formErrorDiv.classList.remove('hidden');
    formErrorDiv.innerHTML = `<strong>${error}</strong>`;
    this.nessus.showNotification({ message: error });
  },

  validateForm(validations) {
    return validations.reduce(
      (validationStatus, validFormField) => {
        if (!validFormField) validationStatus = false;

        return validationStatus;
      },
      true,
    );
  },

  async setActivationCode(data = {}) {
    await this.nessus.setActivationCode(data);
    this.hide();
  },

  async createTrial(data) {
    const { defaultStrings } = this.config;
    let resp = { };
    const payload = {
      ...data,
      product: this.config.product,
      skipContactLookup: true,
    };

    try {
      const response = await fetch(`${this.config.env.baseUrl}/evaluations/api/v1/nessus/trials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const json = await response.json();
      if (!response.ok) throw new Error(json?.message ?? response.statusText);

      resp = json;
    } catch (err) {
      console.error('Create Trial Error: ', err);
      this.showFormError(err?.message ?? defaultStrings.defaultError);
    }

    return resp;
  },

  async handleSubmit(e) {
    e.preventDefault();
    this.showSendingState();

    const data = {
      first_name: this.escapeHTML(this.config.firstName.value),
      last_name: this.escapeHTML(this.config.lastName.value),
      email: this.escapeHTML(this.config.email.value),
      phone: this.escapeHTML(this.config.phone.value),
      company: this.escapeHTML(this.config.company.value),
      title: this.escapeHTML(this.config.title.value),
      companySize: this.escapeHTML(this.config.companySize.value),
      metaData: {
        requestType: 'New Contact',
        email: window?.activation?.state?.contact?.email,
        product: this.config.product,
      },
    };

    const isValid = this.validateForm(
      [
        this.checkFieldValue(data.first_name, this.config.firstName, this.config.defaultStrings.firstName, this.config),
        this.checkFieldValue(data.last_name, this.config.lastName, this.config.defaultStrings.lastName, this.config),
        this.checkEmailDomain(data.email, this.config.email, this.config),
        this.checkFieldValue(data.phone, this.config.phone, this.config.defaultStrings.phone, this.config),
        this.checkFieldValue(data.company, this.config.company, this.config.defaultStrings.company, this.config),
        this.checkFieldValue(data.title, this.config.title, this.config.defaultStrings.title, this.config),
        this.checkSelectValue(
          data.companySize, this.config.companySize, this.config.defaultStrings.companySize, this.config,
        ),
      ],
    );

    if (isValid) {
      const resp = await this.createTrial(data);
      if (resp.code) await this.setActivationCode(resp);
    }

    this.hideSendingState();
  },

  handleBackClick() {
    this.hide();
    this.back();
  },

  addEventListeners() {
    this.config.submitBtn.addEventListener('click', (e) => this.handleSubmit(e));
    this.config.backBtn.addEventListener('click', (e) => this.handleBackClick(e));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.email.addEventListener(e, (event) => this.validateField(event, this.config.email, this.config.defaultStrings.email, this.config, 'email')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.firstName.addEventListener(e, (event) => this.validateField(event, this.config.firstName, this.config.defaultStrings.firstName, this.config, 'first_name')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.lastName.addEventListener(e, (event) => this.validateField(event, this.config.lastName, this.config.defaultStrings.lastName, this.config, 'last_name')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.phone.addEventListener(e, (event) => this.validateField(event, this.config.phone, this.config.defaultStrings.phone, this.config, 'phone')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.title.addEventListener(e, (event) => this.validateField(event, this.config.title, this.config.defaultStrings.title, this.config, 'title')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.company.addEventListener(e, (event) => this.validateField(event, this.config.company, this.config.defaultStrings.company, this.config, 'company')));
    ['keyup', 'change', 'blur'].forEach((e) => this.config.companySize.addEventListener(e, (event) => this.validateField(event, this.config.companySize, this.config.defaultStrings.companySize, this.config, 'companySize')));
  },

  setDefaultValues() {
    this.config.email.value = window?.activation?.state?.contact?.email ?? '';
  },

  render({ targetDiv, parentDiv, env }) {
    const product = window?.activation?.trial?.state?.product ?? 'nessus';
    const defaultStrings = this.utils.getLocaleStrings(strings);

    targetDiv.classList.remove('hidden');
    targetDiv.innerHTML = this.generateHTML({ defaultStrings });

    const formDiv = targetDiv.querySelector('[data-name="activation-new-eval-form"]');
    this.config = {
      env: this.utils.getEnvVars(env),
      defaultStrings,
      targetDiv,
      parentDiv,
      product,
      fieldError: true,
      formDiv,
      formErrorDiv: formDiv.querySelector('[data-name="form-error"]'),
      submitBtn: formDiv.querySelector('[data-name="btn-continue"]'),
      backBtn: formDiv.querySelector('[data-name="btn-back"]'),
      email: formDiv.querySelector('[name=email]'),
      firstName: formDiv.querySelector('[name=first_name]'),
      lastName: formDiv.querySelector('[name=last_name]'),
      phone: formDiv.querySelector('[name=phone]'),
      title: formDiv.querySelector('[name=job-title]'),
      company: formDiv.querySelector('[name=company-name]'),
      companySize: formDiv.querySelector('[name=company-size]'),
    };

    this.nessus = new Nessus(this.config);
    this.nessus.initSelectInputs();
    this.nessus.centerView();

    this.setDefaultValues();
    this.addEventListeners();
  },

  hide() {
    this.config.targetDiv.classList.add('hidden');
  },

  show({ parentDiv, back = () => {} }) {
    const targetDiv = parentDiv.querySelector(`#${this.targetDivSelector}`);
    const { env = 'production' } = parentDiv.dataset;

    this.back = () => {
      targetDiv.innerHTML = '';
      back();
    };
    this.render({ targetDiv, parentDiv, env });
  },
};

export default newContact;
